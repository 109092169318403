import { Button } from 'primereact/button'
import React, { useEffect, useRef, useState } from 'react'
import { useCapsule } from 'react-capsule'
import Flex from '../../Components/Common/Flex'
import { cap } from '../../State/Capsules'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useQuery } from 'react-query'
import { clientService, FC } from '../../Services/Client'
import Form from './Form'
import { Spinner } from '../../Components/Common/Spinner'
import { InputText } from 'primereact/inputtext'

const getClients = async ({ queryKey }, Authorization) => {
  const res = await FC.service('clients').find({ query: {}, headers: { Authorization } })
  return res.data
}

function useWindowSize () {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize () {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

const Home = () => {
  const [edit, setEdit] = useState(false)
  const dt = useRef(null)
  const [globalFilter, setGlobalFilter] = useState('')

  const [Auth, setAuth] = useCapsule(cap.Authorization)
  const { data: clients = [] } = useQuery('clients', async (e) => await getClients(e, Auth), { refetchInterval: 30000 })

  const size = useWindowSize()
  const percForm = (250 / size.width)
  const percDataTable = 1 - percForm

  const logout = () => {
    setAuth('')
    window.location.reload()
  }

  const deleteRecord = async (id, deleteLabel) => {
    await FC.service('clients').remove(id, { headers: { Authorization: Auth } })
    clientService.invalidateQueries('clients')
  }

  const DeleteButton = ({ id }) => {
    const [confirm, setConfirm] = useState(false)
    return (
      <Button
        style={{ margin: '0px -10px' }}
        label={!confirm ? 'Elimina' : 'Conferma per eliminare'} onClick={() => {
          if (!confirm) {
            setConfirm(true)
            setTimeout(() => setConfirm(false), 3000)
          } else deleteRecord(id)
        }}
      />
    )
  }

  const SendMailButton = ({ client, resend }) => {
    const [confirm, setConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    return loading
      ? <Flex fw><Spinner /></Flex>
      : (
        <Button
          label={!confirm ? resend ? 'File già inviato! Vuoi rimandare la mail?' : 'File pronto! Invia Credenziali via mail' : 'Conferma per inviare mail'}
          onClick={
            async () => {
              if (!confirm) {
                setConfirm(true)
                setTimeout(() => setConfirm(false), 3000)
              } else {
                setLoading(true)
                const accept = async () => {
                  await FC.service('files').create({ ...client, usePec: true })
                  clientService.invalidateQueries('clients')
                }
                // const reject = async () => {
                //   await FC.service('files').create({ ...client, usePec: false })
                //   clientService.invalidateQueries('clients')
                // }
                await accept()
                setTimeout(() => {
                  setLoading(false)
                }, 2000)
              }
            }
          }
          style={{ backgroundColor: resend && 'orange', borderColor: resend && 'orange', margin: '0px -10px' }}
        />)
  }

  const header = (
    <div style={{ textAlign: 'right' }}>
      <InputText type='search' value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder='Ricerca società' />
    </div>
  )

  return (
    <>
      <Button label='Logout' onClick={() => logout()} style={{ position: 'absolute', right: 10, top: 10 }} />
      <Flex style={{ borderRadius: 20, marginBottom: 20 }}>
        <h1>Società</h1>
        <Flex row as style={{ paddingTop: 20 }}>
          <DataTable
            globalFilter={globalFilter} emptyMessage='Nessuna società trovata.'
            ref={dt} header={header} value={clients} paginator rows={20} scrollable scrollHeight={`${size.height * 0.7}px`} style={{ width: (window.innerWidth * percDataTable) - 20, marginLeft: 20 }}
          >
            <Column field='name' header='Nome' sortable style={{ width: 200 }} />
            <Column field='society' header='Codice Società' sortable style={{ width: 100 }} />
            <Column field='pIva' header='P.Iva' sortable style={{ width: 200 }} />
            <Column
              style={{ width: 150 }}
              field='status' header='Stato' sortable body={(row) => {
                return row.status === 4
                  ? <Flex style={{ backgroundColor: 'green', padding: 10, textAlign: 'center', borderRadius: 5, color: 'white', margin: '0px -10px' }}>File scaricato dalla società</Flex>
                  : row.status === 3
                    ? <SendMailButton client={row} resend />
                    : row.status === 2
                      ? <SendMailButton client={row} />
                      : <Flex style={{ backgroundColor: 'black', padding: 10, textAlign: 'center', borderRadius: 5, color: 'white', margin: '0px -10px' }}>File non ancora trovato</Flex>
              }}
            />
            <Column field='mailSentAt' header='Data Invio Mail' style={{ width: 150 }} sortable body={({ mailSentAt }) => mailSentAt ? new Date(mailSentAt)?.toLocaleString() : ''} />
            <Column field='downloadedAt' header='Data Download' style={{ width: 150 }} sortable body={({ downloadedAt }) => downloadedAt ? new Date(downloadedAt)?.toLocaleString() : ''} />
            <Column field='file' header='File' sortable style={{ width: 300 }} />
            {/*  <Column field='email' header='Email' sortable /> */}
            <Column field='pec' header='PEC' sortable style={{ width: 300 }} />
            <Column
              style={{ width: 100 }}
              header='Elimina' body={(v) => <DeleteButton id={v._id} />}
            />
            <Column
              style={{ width: 100 }}
              header='Modifica' body={(v) => <Button
                style={{ margin: '0px -10px' }}
                label='Modifica' onClick={() => {
                  setEdit(v)
                }}
                                             />}
            />
          </DataTable>
          <div style={{ width: window.innerWidth * percForm }}>
            <Form edit={edit} setEdit={setEdit} />
          </div>
        </Flex>
      </Flex>
    </>
  )
}

export default Home
